import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  private scrollPos = new BehaviorSubject<number>(0); // Create a BehaviorSubject

  currentPos$ = this.scrollPos.asObservable(); 

  constructor() { }

  setScrollPos(num:number){
    this.scrollPos.next(num);
  }
}
